import React, { Component } from 'react';
import PackeryMod from 'react-packery-component';
import { withWindowSizeListener } from 'react-window-size-listener';
import photos from 'sixrockets/photos';
import './App.css';

const Packery = PackeryMod(React);

const packeryOptions = {
  transitionDuration: 0,
  itemSelector: '.grid-item',
  gutter: 0,
  columnWidth: '.grid-sizer',
  percentPosition: true
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };

class App extends Component {
  render() {
    const width = this.props.windowSize.windowWidth;
    if (!width) return <div />;
    let numColumns = 5;
    switch (true) {
      case width <= 768:
        numColumns = 2;
        break;
      case width <= 1200:
        numColumns = 3;
        break;
      case width <= 1640:
        numColumns = 4;
        break;
      default:
    }
    const columnWidth = (1 / numColumns);
    const childElements = photos.map(function(element) {
      let actualWidth = columnWidth * width;
      let actualHeight = actualWidth;
      const imageRatio = element.mediaMetadata.width / element.mediaMetadata.height;
      if (Math.random() < .15) {
        if (imageRatio > 1){
          actualWidth  = actualWidth * 2
          if (Math.random() < .25) actualHeight = actualHeight*2;
        }
        else if (imageRatio < 1){
          actualHeight = actualHeight * 2
          if (Math.random() < .25) actualWidth = actualWidth * 2;
        }
      }
      return (
        <div
          className="grid-item"
          key={element.id}
          style={{width: `${actualWidth}px`, height: `${actualHeight}px`}}
        >
          <img
            src={`${element.baseUrl}`}
            alt={element.filename}
          />
        </div>
      );
    });

    return (
      <Packery
        className={'grid'} // default ''
        elementType={'div'} // default 'div'
        options={packeryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
        <div className="grid-sizer" style={{width: `${columnWidth * 100}%`}}></div>
        {childElements}
      </Packery>
    );
  }
}

export default withWindowSizeListener(App);
